/*************  ✨ Codeium Command 🌟  *************/
import { AgGridReact } from "ag-grid-react";
import "../../../node_modules/ag-grid-community/styles/ag-grid.css";
import "../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
import { measurementProps } from "../data-types";
import { MeasurementsDisplayProps} from "../component-types";
import { getAndSetSourceMeasurements } from "../../controllers/data";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useState, useEffect } from "react";
import { useUserContext } from "../../contexts/user-context";
import  Plot from 'react-plotly.js';

function MeasurementsDisplay({ selectedSource }: MeasurementsDisplayProps) {
  const [sourceMeasurements, setSourceMeasurements] = useState<Array<measurementProps> | [] > ([]);
  const [selectedMeasurements, setSelectedMeasurements] = useState<Array<measurementProps> | [] > ([]);
  const { user } = useUserContext();

  useEffect(() => {
    getAndSetSourceMeasurements(user, selectedSource.sourceId, setSourceMeasurements, () => {});
  }, [selectedSource]);


  function OpenMeasurement(measurement: ICellRendererParams<any, any, any>){
    //Implement setting the selected data measurement and rendering measurement data here
    var name: string = measurement.node.data.name;
    setSelectedMeasurements(
      sourceMeasurements.filter(measurement => measurement.name === name)
    );
}

  
  function OpenMeasurementButton(props: ICellRendererParams){
    return (
      <Button variant="success" onClick={() => OpenMeasurement(props)}> 
        Open
      </Button>
    );
  }

  // Column Definitions: Defines the columns to be displayed.
  const columnDefs: ColDef[] = [
    {
      flex: 1,
      headerName: "Name",
      field: 'name',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: "Value",
      field: 'vame',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: "Unit",
      field: 'unit',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1.5,
      headerName: 'Description',
      field: 'info',
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Created',
      field: 'createdAt',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Updated',
      field: 'updatedAt',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: .5,
      field: 'action',
      headerName: '',
      cellRenderer: OpenMeasurementButton,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
  ];

  return sourceMeasurements ? (
    // wrapping container with theme & size
    <div
    className="ag-theme-alpine" // applying the Data Grid theme
    style={{
      height: 500
    }} // the Data Grid will fill the size of the parent container
    >
      //if selected measurement then render the graph, otherwise skip this

    {selectedMeasurements.length > 0 && (
      <Plot
        data={[
                {
                  x: selectedMeasurements.map(measurement => measurement.createdAt),
                  y: selectedMeasurements.map(measurement => measurement.value),
                  type: 'scatter',
                  mode: 'lines+markers',
                  marker: {color: 'red'},
                }
              ]}
        layout={
                {
                  width: 320, 
                  height: 240, 
                  title: {
                      text: 'Selected Measurements Over Time'
                    }
                }
               }
      />
    )}

      <AgGridReact
          rowData={sourceMeasurements}
          columnDefs={columnDefs}
          rowHeight={50}
      />

    
    </div>
) : (
  <div>
    <h1> No measurements to display</h1>;
  </div>
)
}

export default MeasurementsDisplay;