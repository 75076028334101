import Container from "react-bootstrap/Container";
import AppNavBar from "../components/widgets/nav-bar";
import DataManager from "../components/data-manager/data-manager";
import { UserHomePageProps } from "../components/component-types";

const HomePage = ({}: UserHomePageProps) => {  
  return (
    <div>
      <AppNavBar />
      <Container
        style={{
          paddingTop: "20px",
        }}
      >
        <DataManager/>
      </Container>
    </div>
  );
};

export default HomePage;
