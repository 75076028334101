import { AgGridReact } from "ag-grid-react";
import "../../../node_modules/ag-grid-community/styles/ag-grid.css";
import "../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
import { sourceProps } from "../data-types";
import { SourcesGridProps} from "../component-types";
import { getAndSetCollectionSources } from "../../controllers/data";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useState, useEffect } from "react";
import { useUserContext } from "../../contexts/user-context";
import { Col } from "react-bootstrap";

function SourcesGrid({ userCollection, setSelectedCollection, setSelectedSource }: SourcesGridProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [collectionSources, setCollectionSources] = useState<Array<sourceProps> | [] > ([]);
  const { user } = useUserContext();

  useEffect(() => {
    getAndSetCollectionSources(user, userCollection.collectionId, setCollectionSources, () => setIsLoading(false));
  }, [userCollection]);
  
  function openSource(source: ICellRendererParams<any, any, any>){
    //Implement setting collection context and navigation to data sources page here
    setSelectedSource(source.node.data);
    //setIsLoading(true)
    //console.log(collection.node.data)
  }

  function OpenSourceButton(props: ICellRendererParams){
    return (
      <Button variant="success" onClick={() => openSource(props)}> 
        Open
      </Button>
    );
  }

  // Column Definitions: Defines the columns to be displayed.
  const columnDefs: ColDef[] = [
    {
      flex: 1,
      headerName: "Name",
      field: 'name',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1.5,
      headerName: 'Description',
      field: 'info',
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Created',
      field: 'createdAt',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Updated',
      field: 'updatedAt',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: .5,
      field: 'action',
      headerName: '',
      cellRenderer: OpenSourceButton,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
  ];

  return collectionSources ? (
    // wrapping container with theme & size
    <div
    className="ag-theme-alpine" // applying the Data Grid theme
    style={{
      height: 500
    }} // the Data Grid will fill the size of the parent container
    >
      <AgGridReact
          rowData={collectionSources}
          columnDefs={columnDefs}
          rowHeight={50}
          loading={isLoading}
      />

    <Col xl={3}>
    <div style={{ marginTop: "1cm"}}>

      <Button variant="secondary" onClick={() => setSelectedCollection(null)}>
        Back
      </Button>

    </div>
    </Col>
    <Col xl={6} />
    </div>
) : (
  <div>
    <h1> No sources to display</h1>;

    <Col xl={3}>
      <div style={{ marginTop: "1cm"}}>
        <Button variant="secondary" onClick={() => setSelectedCollection(null)}>
          Back
        </Button>
    </div>  
    </Col>
    <Col xl={6} />
  </div>
)
}

export default SourcesGrid;