import axios from "axios";
import { dataServerAddress } from "../configs/env";
import { adminUserId } from "../secrets";
import {
  userProps,
  newCollectionProps,
  collectionProps,
  collectionsApiResponseProps,
  newSourceProps,
  sourceProps,
  sourcesApiResponseProps,
  newMeasurementProps,
  measurementProps,
  measurementsApiResponseProps
} from "../components/data-types";

export const submitCollection = async (
  collection: newCollectionProps,
  user: userProps,
  callback: { (): void },
): Promise<void> => {
  const fullPath = `${dataServerAddress}/collections/create/`;
  const reqBody = { //field names for oasis-data must be in snake case
    session: {
      user_id: user.userId,
      id_token: user.idToken,
      admin_id: adminUserId,
      group_name: "oasis-users",
    },
    new_collection: {
      name: collection.name,
      info: collection.info,
      user_id: user.userId
    }
  };

  axios
    .post(fullPath, reqBody)
    .then((response) => {
      const resBody = response.data;
      if (resBody.succeeded) {
        return callback();
      } else {
        return callback();
      }
    })
    .catch((errors) => {
      return console.log(errors);
    });
};

export const getAndSetUserCollections = (
  user: userProps,
  setCollections: { (collections: collectionProps[]): void},
  callback: { (): void }
): Promise<void> => {
  const fullPath = `${dataServerAddress}/collections/read/`;

  const reqBody = { //field names for oasis-data must be in snake case
    user_id: user.userId,
    id_token: user.idToken,
    admin_id: adminUserId,
    group_name: "oasis-users",
  };

  const getAndSet = async () => {
    await axios
      .post(fullPath, reqBody)
      .then((response) => {
        if (response && response.data) {
          const body = response.data;

          const userCollections: collectionProps[] = []
          
          body.data.forEach((element: collectionsApiResponseProps) => {
            
            const collection: collectionProps = {
              name: element.name,
              info: element.info,
              collectionId: element.collection_id,
              createdAt: element.created_at,
              createdBy: element.created_by,
              updatedAt: element.updated_at,
              updatedBy: element.updated_by,
            };
            
            userCollections.push(collection);
        });
        
        setCollections(userCollections);
        }
      })
      .catch((errors) => {
        console.error(errors);
      })
      .finally(() => callback());
  };

  return getAndSet();
};

export const getAndSetCollectionSources = (
  user: userProps,
  collectionId: Number,
  setCollectionSources: { (sources: sourceProps[]): void},
  callback: { (): void }
): Promise<void> => {
  const fullPath = `${dataServerAddress}/sources/read/`;

  const reqBody = { //field names for oasis-data must be in camel case
    
    session: {user_id: user.userId,
              id_token: user.idToken,
              admin_id: adminUserId,
              group_name: "oasis-users"},
    
    collection_id: collectionId
  };

  const getAndSet = async () => {
    await axios
      .post(fullPath, reqBody)
      .then((response) => {
        if (response && response.data) {
          const body = response.data;
          
          const collectionSources: sourceProps[] = []
          
          body.data.forEach((element: sourcesApiResponseProps) => {
            const source: sourceProps = {
              collectionId: element.collection_id,
              name: element.name,
              info: element.info,
              sourceId: element.source_id,
              createdAt: element.created_at,
              createdBy: element.created_by,
              updatedAt: element.updated_at,
              updatedBy: element.updated_by
            };

            collectionSources.push(source)
          });

          return setCollectionSources(collectionSources);
        }
      })
      .catch((errors) => {
        return console.error(errors);
      })
      .finally (() => callback());
  };

  return getAndSet();
};

export const submitSource = async (
  source: newSourceProps,
  user: userProps,
  callback: { (): void },
): Promise<void> => {
  const fullPath = `${dataServerAddress}/sources/create/`;
  const reqBody = { //field names for oasis-data must be in snake case
    session: {
      user_id: user.userId,
      id_token: user.idToken,
      admin_id: adminUserId,
      group_name: "oasis-users",
    },
    new_source: {
      collection_id: source.collectionId,
      name: source.name,
      info: source.info
    }
  };

  axios
    .post(fullPath, reqBody)
    .then((response) => {
      const resBody = response.data;
      if (resBody.succeeded) {
        return callback();
      } else {
        return callback();
      }
    })
    .catch((errors) => {
      return console.log(errors);
    });
};

export const getAndSetSourceMeasurements = (
  user: userProps,
  sourceId: Number,
  setSourceMeasurements: { (measurements: measurementProps[]): void},
  callback: { (): void }
): Promise<void> => {
  const fullPath = `${dataServerAddress}/measurements/read/`;

  const reqBody = { //field names for oasis-data must be in camel case
    session: {
      user_id: user.userId,
      id_token: user.idToken,
      admin_id: adminUserId,
      group_name: "oasis-users"
    },
    measurement_target: {
      source_id: sourceId,
    },
    mode: "all_from_source"
  };

  const getAndSet = async () => {
    await axios
      .post(fullPath, reqBody)
      .then((response) => {
        if (response && response.data) {
          const body = response.data;
          
          const sourceMeasurements: measurementProps[] = []
          
          body.data.forEach((element: measurementsApiResponseProps) => {
            const measurement: measurementProps = {
              measurementId: element.measurement_id,
              sourceId: element.source_id,
              name: element.name,
              value: element.value,
              unit: element.unit,
              info: element.info,
              createdAt: element.created_at,
              createdBy: element.created_by,
              updatedAt: element.updated_at,
              updatedBy: element.updated_by
            };

            sourceMeasurements.push(measurement)
          });

          return setSourceMeasurements(sourceMeasurements);
        }
      })
      .catch((errors) => {
        return console.error(errors);
      })
      .finally (() => callback());
  };

  return getAndSet();
};

export const submitMeasurement = async (
  measurement: newMeasurementProps,
  user: userProps,
  callback: { (): void },
): Promise<void> => {
  const fullPath = `${dataServerAddress}/measurements/create/`;
  const reqBody = { //field names for oasis-data must be in snake case
    session: {
      user_id: user.userId,
      id_token: user.idToken,
      admin_id: adminUserId,
      group_name: "oasis-users",
    },
    new_measurement: {
      source_id: measurement.sourceId,
      name: measurement.name,
      value: measurement.value,
      unit: measurement.unit,
      info: measurement.info
    }
  };

  axios
    .post(fullPath, reqBody)
    .then((response) => {
      const resBody = response.data;
      if (resBody.succeeded) {
        return callback();
      } else {
        return callback();
      }
    })
    .catch((errors) => {
      return console.log(errors);
    });
};