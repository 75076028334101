import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { UserProvider } from "./contexts/user-context";
import App from "./App";
import "./index.css";

const container = document.getElementById("root")!;
const root = createRoot(container);


root.render(
  <React.StrictMode>
    <script
      src="https://cdnjs.cloudflare.com/ajax/libs/paho-mqtt/1.0.1/mqttws31.min.js"
      type="text/javascript"
    ></script>
    <div className="App">
      <Router>
        <UserProvider>
          <App />
        </UserProvider>
      </Router>
    </div>
  </React.StrictMode>,
);
