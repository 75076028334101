import { useState, useEffect, useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { collectionProps, newCollectionProps, sourceProps, newSourceProps } from "../data-types";
import { DataManagerProps } from "../component-types";
import CollectionManager from "./data-manager-components/collection-manager";
import SourceManager from "./data-manager-components/source-manager";
import SourceChildrenManager from "./data-manager-components/source-children-manager";
import { getAndSetUserCollections } from "../../controllers/data";
import { useUserContext } from "../../contexts/user-context";

function DataManager({}: DataManagerProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useUserContext();
  const [userCollections, setUserCollections] = useState<Array<collectionProps>>([]);
  const getCollections = useCallback( async () => {
    setIsLoading(true)
    return getAndSetUserCollections(user, setUserCollections, () => setIsLoading(false));
  }, [user])
  useEffect(() => {
    getCollections();
  }, [user, getCollections]);
  const [selectedCollection, setSelectedCollection] = useState<collectionProps | null>(null)
  const [selectedSource, setSelectedSource] = useState<sourceProps | null>(null)

  return isLoading ? (
    <div/>
  ) : (
    <div className="DataManager__Page">
      {
        !selectedCollection && (
          <CollectionManager
            userCollections={userCollections}
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
            refreshCollections={getCollections}
            setIsLoading={setIsLoading}
          />
        )
      }
      {
        selectedCollection && !selectedSource && (
          <SourceManager
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
            setSelectedSource={setSelectedSource}
          />
        )
      }
      {
        selectedCollection && selectedSource && (
          <SourceChildrenManager
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />
        )
      }
    </div>
  );
}

export default DataManager;
