import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextInput from "../inputs/text";
import Button from "react-bootstrap/Button";
import { submitMeasurement } from "../../controllers/data";
import { MeasurementFormProps } from "../component-types";
import { useUserContext } from "../../contexts/user-context";

function AddMeasurementForm({
  newMeasurement,
  setFieldValue,
  toggleAddingMeasurement,
}: MeasurementFormProps) {
  const { user } = useUserContext();

  const submit = () => {
    return submitMeasurement(newMeasurement, user, toggleAddingMeasurement);
  };

  return (
    <div className="MeasurementForm">
      <Row className="align-items-start">
        <h2> New Measurement </h2>
      </Row>
      <Row>
        <TextInput
          id="task-name-input"
          label="Name"
          fieldName="name"
          value={newMeasurement.name}
          setFieldValue={setFieldValue}
          colWidth={6}
        />
      </Row>
      <div className="padding" />
      <Row>
        <TextInput
          id="task-name-input"
          label="Value"
          fieldName="value"
          value={""}
          setFieldValue={() => {}}
          colWidth={6}
        />
      </Row>
      <div className="padding" />
      <Row>
        <TextInput
          id="task-name-input"
          label="Unit - BROKEN"
          fieldName="unit"
          value={newMeasurement.unit}
          setFieldValue={setFieldValue}
          colWidth={6}
        />
      </Row>
      <div className="padding" />
      <Row>
        <TextInput
          id="task-info-input"
          label="Info"
          fieldName="info"
          value={newMeasurement.info}
          setFieldValue={setFieldValue}
          colWidth={12}
        />
      </Row>
      <div className="padding" />
      <Row>
        <Col xl={3}>
          <Button variant="secondary" onClick={() => toggleAddingMeasurement()}>
            Cancel
          </Button>
        </Col>
        <Col xl={6} />
        <Col xl={3}>
          <Button variant="success" onClick={() => submit()}>
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddMeasurementForm;
