import { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { newCollectionProps } from "../../data-types";
import { CollectionManagerProps } from "../../component-types";
import CollectionsGrid from "../../widgets/collections-grid";
import SourceManager from "./source-manager";
import AddCollectionForm from "../../forms/add-collection";

function CollectionManager({
  userCollections,
  selectedCollection,
  setSelectedCollection,
  refreshCollections,
  setIsLoading
}: CollectionManagerProps) {
  const [addingCollection, setAddingCollection] = useState<boolean>(false);

  const [newCollection, setNewCollection] = useState<newCollectionProps>({
    name: "",
    info: "",
  });

  const setFieldValue = (keyOrObject: any, value: any): void => {
    if (typeof keyOrObject === "object") {
      setNewCollection((existingValues) => ({
        ...existingValues,
        ...keyOrObject,
      }));
    } else {
      setNewCollection((existingValues) => ({
        ...existingValues,
        [keyOrObject]: value,
      }));
    }
  };

  const toggleAddingCollection = () => {
    return setAddingCollection(!addingCollection);
  };

  return (
    <div className="CollectionsManager__Page">
      {addingCollection && (
        <div className="AddCollection__Form">
          <AddCollectionForm
            newCollection={newCollection}
            setFieldValue={setFieldValue}
            toggleAddingCollection={toggleAddingCollection}
            refreshCollections={refreshCollections}
          />
        </div>
      )}
      {!addingCollection && (
        <div className="CollectionsManager__Widget">
          <Row>
            <Col xl={12}>
              <h2>Welcome to the Oasis Dashboard!</h2>
            </Col>
          </Row>

          {!selectedCollection && (
            <div>
              <Stack direction="horizontal" gap={3} border-radius="10px">
                <div className="lgHeader" style={{ fontSize: '24px' }}>
                  Collection Manager
                </div>

                <div className="ms-auto">
                  <div className="medium-padding" />
                  <Button variant="success" onClick={() => toggleAddingCollection()}>
                    Add New Collection
                  </Button>
                </div>

              </Stack>

              <CollectionsGrid
                userCollections={userCollections}
                setSelectedCollection={setSelectedCollection}
                setIsLoading={setIsLoading}
              />

            </div>
          )
          }
        </div>
      )}
    </div>
  );
}

export default CollectionManager;
