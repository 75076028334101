import { useState } from 'react';
import { SourceChildrenManagerProps } from "../../component-types";
import { newMeasurementProps } from "../../data-types";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import AddMeasurementForm from "../../forms/add-measurement";
import MeasurementsDisplay from "../../widgets/measurements-display";

//Plotly
//Grid shows everything consolidated by name

function SourceChildrenManager({
  selectedSource,
  setSelectedSource
}: SourceChildrenManagerProps) {
  const [addingMeasurement, setAddingMeasurement] = useState<boolean>(false);
  const [newMeasurement, setNewMeasurement] = useState<newMeasurementProps>({
    sourceId: selectedSource.sourceId,
    name: "",
    value: 0,
    unit: "",
    info: ""
  });
  const setNewMeasurementFieldValue = (keyOrObject: any, value: any): void => {
    if (typeof keyOrObject === "object") {
      setNewMeasurement((existingValues) => ({
        ...existingValues,
        ...keyOrObject,
      }));
    } else {
      setNewMeasurement((existingValues) => ({
        ...existingValues,
        [keyOrObject]: value,
      }));
    }
  };
  const toggleAddingMeasurement = () => {
    return setAddingMeasurement(!addingMeasurement);
  };
  
  return (
    <div className="SourceChildrenManager__Page">
      {!addingMeasurement && (
        <div>
        <Stack direction="horizontal" gap={3} border-radius="10px">
          <div className="lgHeader" style={{ fontSize: '24px' }}>
            {selectedSource.name}
          </div>

          <div className="ms-auto">
            <div className="medium-padding" />
            <Button variant="success" onClick={() => toggleAddingMeasurement()}>
              Add New Measurement
            </Button>
          </div>

        </Stack>

        <MeasurementsDisplay
          selectedSource={selectedSource}
        />
      </div>
      )}
      {addingMeasurement && (
        <div className="AddMeasurement__Form">
          <AddMeasurementForm
            newMeasurement={newMeasurement}
            setFieldValue={setNewMeasurementFieldValue}
            toggleAddingMeasurement={toggleAddingMeasurement}
          />
        </div>
      )}
      <div style={{ marginTop: "1cm"}}>
        <Button variant="secondary" onClick={() => setSelectedSource(null)}>
          Back
        </Button>
      </div>  
    </div>
  );
}

export default SourceChildrenManager;
